import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"



const EdgePage = () => (
    <Layout>
    <Seo title="Home" />
        <div className="w-full min-h-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto font-['Inter'] mb-20">
            <div className="grid grid-cols-12 gap-0 ">
                
                <div className="col-span-12 mt-20 md:mt-28 flex justify-center items-center p-4">
                    <div className="w-10/12 md:w-12/12 text-4xl md:text-4xl xl:text-5xl text-center font-medium text-[#08408F] tracking-tight">
                        We love building digital products.
                    </div>
                </div>

               
                <div className="col-span-12 md:col-span-5 mt:0 md:mt-2 flex justify-center items-center ">
                    <div className="w-10/12 md:w-12/12 mx-auto mt-2">
                        <div className=" text-base text-left mt-8 md:mt-6 text-zinc-900 font-normal leading-8 tracking-wide">
                        We are small yet creative team that thrives to make stuff that people would love to use.  Our love for the technologies both proven and emerging that drives us to learn, evaluate and use them in an effiecient way. Keeping a tab on the latest Open Source technologies that helps us provide better and cost effective solutions to our clients. 
                        </div>
                     </div>
                </div>
                <div className="col-span-12 md:col-span-7 flex justify-start mt-20 p-4 md:p-8 h-auto ">
                    <div className="my-10">
                    <StaticImage src="../images/edge-building-product.svg" alt="Stage of product" width={800}/>
                    </div>
                </div>
                <div className="col-start-1 md:col-start-2 col-span-12 md:col-span-5 p-7 md:p-1  ">
                    <div className=" text-base text-left  text-zinc-900 font-normal leading-8 tracking-wide">
                        <div className="mt-1">   Our focus is our clients and we start with building 
                            relationship, establishing better communication and 
                            staying disciplined and devoted to the success of the project. 
                            We belive if we will be equally passionate and excited about your 
                            idea or project then the outcome wouldn’t be any less than perfect. 
                        </div>      
                        <div className="mt-5">   Internalizing these ideas helps us providing our clients with
                             the best solutions and remote engineers. 
                        </div>                 
                    </div>
                </div>


               
                <div className="col-span-12">

                    <div className="w-10/12 md:w-10/12 p-2 text-2xl md:text-2xl mt-20 font-medium text-[#08408F] tracking-tight">
                        Functional Values
                        <div className="h-6 w-48 bg-blue-800 my-4">

                        </div>
                    </div>
                    <div className=" text-base text-left  text-zinc-900 font-normal leading-8 tracking-wide">
                        <div className="p-4 pt-8">  Whether you come to us for Product or Engineering Talent, we abdie by the values which helps us deliver quality and a perfect customer experience. 
                        Internalizing these ideas helps us providing our clients with
                             the best solutions and remote engineers.
                        </div>               
                    </div>
                </div>
                


        <div className="col-span-12 mt-0 md:mt-20 p-10">
            <div className="w-full lg:w-10/12 mx-auto">
                <div className="grid grid-cols-12 gap-2">
                    <div className="col-span-3 mt-10 flex justify-center items-center flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="blue" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                        </svg>
                    </div>
                    <div className="col-span-9 mt-10 ">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8">
                        Clear communication
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2 leading-6">
                            Communication is the key element of any successful project. Keeping the stakeholders on the same page as the engineer on the team
                            is vital for us, as this helps us navigate roadblocks during the project course. This also provides an opportunity 
                            to our stakeholders and us to work around any unforseen changes resulting in better outcome. 
                        </div>                        
                    </div>

                    <div className="col-span-3 mt-10 flex justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="violet" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-target"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>
                    </div>
                    <div className="col-span-9 mt-10">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8">
                        Defined Goal
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2 leading-6 ">
                            For a timely and desired outcome we formulate our goal so as to attain them in planned time frame and budget.
                            This gives us a clear direction and helps us move forward at speed, yet stay focuses on the purpose of the project.
                            Well defined goal helps us align ourselves with our clients and help deliver a great client experience.
                        </div>                        
                    </div>

                    <div className="col-span-3 mt-10 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="orange" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                    </div>
                    <div className="col-span-9 mt-10">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8">
                        Adaptive Team
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2  leading-6">
                            At times changing conditions or goals risks performance and outcomes and any oversight of such changes can risk the entire project.
                            Our ability to prepare for and adapt to changing conditions and at times evolving goals keeps us agile. We keep our foresight and learning throughout the project
                            and keep aware of any changes or risks which helps us keep our team prepared and adaptive in moving forward towards successful completion.
                        </div>                        
                    </div>


                    <div className="col-span-3 mt-10 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="darkgreen" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bar-chart"><line x1="12" y1="20" x2="12" y2="10"></line><line x1="18" y1="20" x2="18" y2="4"></line><line x1="6" y1="20" x2="6" y2="16"></line></svg>
                    </div>
                    <div className="col-span-9 mt-10">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8 ">
                        Performance
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2  ">
                        Measuring performance at both the project and engineering level ensures strategic success. Staying focused on project outcomes and evaluating performance
                        based on established data points, helps us target better performance at both project and individual levels. Transforming goals to tangible action and evaluating it ensures
                        better project success for everyone.
                        </div>                        
                    </div>


                    <div className="col-span-3 mt-10 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="deeppink" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                     </svg>

                    </div>
                    <div className="col-span-9 mt-10">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8">
                        Quality
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2 leading-6 ">
                            Ensuring all the the deliverables are on mark with the project expectations is highly important to us. Monoitoring outupt at every stage, be it product under development or the engineering 
                            resource working , helps ensure standards. Tracking all the aspects, ensuring expectations are met and avoiding mistake by mapping quality metrics enables quality deliverables.
                        </div>                        
                    </div>


                    <div className="col-span-3 mt-10 flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="maroon" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>

                    </div>
                    <div className="col-span-9 mt-10">
                        <div className="text-xl font-medium text-[#08408F] underline underline-offset-8">
                        Flexibility
                        </div>    
                        <div className=" font-normal text-sm text-zinc-900 my-2 p-2 leading-6 ">
                            Goals and requirements can vary since no two projects are alike. Scaling up engineering resources fast or at times just scaling down to shift focus on greater prioritoes. We understand 
                            business cycles that demand greater flexibility. We work to be as elastic as possible with our clients in providing engineering resources 
                            or product development in terms of time and budget for greater success. 
                        </div>                        
                    </div>



                </div> 
                {/* End of grid container of icons and text */}
            </div>
        </div>



                
            </div>
        </div>
    </Layout>
)

export default EdgePage